import { Box, Divider, Text } from "@chakra-ui/react";
import { Strings } from "../../Utils/Strings";

export const FooterBox = () => {
  return (
    <Box
      flex={1}
      w="100%"
      display="flex"
      alignItems="center"
      flexDirection="column"
      paddingX="2rem"
    >
      <Divider borderColor="branding.gray" />
      <Text color="branding.gray" textAlign="center">
        {Strings.GENERAL_STRINGS.COPYRIGHT}
      </Text>
    </Box>
  );
};
