import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import HomePage from "../Pages/HomePage";
import KenatzumuPrismPage from "../Pages/KenatzumuPrismPage";
import DailySheetsPage from "../Pages/DailySheetsPage";
import EMenuPage from "../Pages/Experiments/EMenuPage";
import E1Page from "../Pages/Experiments/E1Page";
import E2Page from "../Pages/Experiments/E2Page";

const MainNavigator = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/kenatzumu-prism" element={<KenatzumuPrismPage />} />
        <Route path="/daily-sheets" element={<DailySheetsPage />} />
        <Route path="/experiments" element={<EMenuPage />} />
        <Route path="/experiments/1" element={<E1Page />} />
        <Route path="/experiments/2" element={<E2Page />} />
      </Routes>
    </Router>
  );
};

export default MainNavigator;
