import React from "react";
import { DoubleSide } from "three";
import { PersistentColors } from "../Utils/Colors";

const CMYCubeRender: React.FC = () => {
  let cubeLatSize = window.innerWidth < 600 ? 25 : 70;

  const colors = [
    PersistentColors.Cyan,
    PersistentColors.Magenta,
    PersistentColors.Yellow,
  ];

  const materialProps = {
    metalness: 0,
    roughness: 0,
    transmission: 1,
    thickness: cubeLatSize,
    transparent: false,
    side: DoubleSide,
  };

  const faceProps = [
    {
      position: [0, 0, -cubeLatSize / 2] as [number, number, number],
      rotation: [-Math.PI, 0, 0] as [number, number, number],
      color: colors[0],
    },
    {
      position: [0, 0, cubeLatSize / 2] as [number, number, number],
      rotation: [Math.PI, Math.PI, 0] as [number, number, number],
      color: colors[0],
    },
    {
      position: [0, -cubeLatSize / 2, 0] as [number, number, number],
      rotation: [Math.PI / 2, 0, 0] as [number, number, number],
      color: colors[1],
    },
    {
      position: [0, cubeLatSize / 2, 0] as [number, number, number],
      rotation: [-Math.PI / 2, 0, 0] as [number, number, number],
      color: colors[1],
    },
    {
      position: [-cubeLatSize / 2, 0, 0] as [number, number, number],
      rotation: [-Math.PI / 2, -Math.PI / 2, 0] as [number, number, number],
      color: colors[2],
    },
    {
      position: [cubeLatSize / 2, 0, 0] as [number, number, number],
      rotation: [Math.PI / 2, Math.PI / 2, 0] as [number, number, number],
      color: colors[2],
    },
  ];

  return (
    <>
      {faceProps.map((props, index) => (
        <mesh key={index} position={props.position} rotation={props.rotation}>
          <planeGeometry args={[cubeLatSize, cubeLatSize, cubeLatSize]} />
          <meshPhysicalMaterial color={props.color} {...materialProps} />
        </mesh>
      ))}
    </>
  );
};

export default CMYCubeRender;
