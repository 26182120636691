import React, { useState, useEffect, ReactNode, useRef } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { Box } from "@chakra-ui/react";
import gsap from "gsap";
import lodash from "lodash";
import * as THREE from "three";
import EExportButtons from "./EExportButtons";

interface ERotatingCanvasProps {
  children?: ReactNode;
}

const ERotatingCanvas: React.FC<ERotatingCanvasProps> = ({ children }) => {
  const [rotationX, setRotationX] = useState(0);
  const [rotationY, setRotationY] = useState(0);
  const [initialRotation, setInitialRotation] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [lastMousePosition, setLastMousePosition] = useState({ x: 0, y: 0 });
  const groupRef = useRef<THREE.Group>(null);
  const sceneRef = useRef<THREE.Scene>(new THREE.Scene());

  const handleMouseMove = lodash.throttle((event: MouseEvent) => {
    if (isDragging) {
      const deltaX = event.clientX - lastMousePosition.x;
      const deltaY = event.clientY - lastMousePosition.y;
      setRotationX((prevRotationX) => prevRotationX + deltaY * 0.01);
      setRotationY((prevRotationY) => prevRotationY + deltaX * 0.01);
      setLastMousePosition({ x: event.clientX, y: event.clientY });
    }
  }, 16);

  useEffect(() => {
    window.addEventListener("mousemove", handleMouseMove);
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, [isDragging, lastMousePosition, handleMouseMove]);

  const handleMouseDown = (event: React.MouseEvent) => {
    setIsDragging(true);
    setLastMousePosition({ x: event.clientX, y: event.clientY });
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    gsap.to(
      { x: rotationX, y: rotationY },
      {
        x: initialRotation.x,
        y: initialRotation.y,
        duration: 0.5,
        ease: "power3.out",
        onUpdate: function () {
          setRotationX(this.targets()[0].x);
          setRotationY(this.targets()[0].y);
        },
      }
    );
  };

  useEffect(() => {
    setInitialRotation({ x: 0, y: 0 });
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      h="100vh"
      backgroundColor="#000000"
    >
      <Canvas
        camera={{ position: [0, 0, 1], fov: 75 }}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onCreated={({ scene }) => {
          sceneRef.current = scene;
        }}
      >
        <ambientLight intensity={0.8} />
        {/* SpotLight positioned at the camera's position and pointing towards -y */}
        <spotLight
          position={[0, 0.5, 1]}
          intensity={1}
          angle={0.8}
          penumbra={0}
          castShadow
        />

        <RotatingGroup
          ref={groupRef}
          rotationX={rotationX}
          rotationY={rotationY}
        >
          {children}
        </RotatingGroup>
      </Canvas>
      {process.env.NODE_ENV === "development" && sceneRef.current != null && (
        <EExportButtons scene={sceneRef.current!} />
      )}
    </Box>
  );
};

export default ERotatingCanvas;

// ANCHOR: Rotating Group component used for Rotating Canvas only.
interface RotatingGroupProps {
  rotationX: number;
  rotationY: number;
  children: React.ReactNode;
}

const RotatingGroup = React.forwardRef<THREE.Group, RotatingGroupProps>(
  ({ rotationX, rotationY, children }, ref) => {
    useFrame(() => {
      if (ref && (ref as React.MutableRefObject<THREE.Group>).current) {
        (ref as React.MutableRefObject<THREE.Group>).current.rotation.x =
          rotationX;
        (ref as React.MutableRefObject<THREE.Group>).current.rotation.y =
          rotationY;
      }
    });

    return (
      <group ref={ref as React.MutableRefObject<THREE.Group>}>{children}</group>
    );
  }
);
