import React, { useEffect, useRef } from "react";
import { Box } from "@chakra-ui/react";

const RandomPixelBackground: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    const colors: [number, number, number][] = [
      [18, 18, 18], // #121212
      [17, 17, 17], // #111111
      [16, 16, 16], // #101010
      [15, 15, 15], // #0F0F0F
      [14, 14, 14], // #0E0E0E
      [20, 20, 20], // #141414
      [13, 13, 13], // #0D0D0D
      [12, 12, 12], // #0C0C0C
      [11, 11, 11], // #0B0B0B
      [10, 10, 10], // #0A0A0A
      [9, 9, 9], // #090909
      [8, 8, 8], // #080808
      [7, 7, 7], // #070707
      [6, 6, 6], // #060606
    ];

    const resizeCanvas = () => {
      if (canvas) {
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;
        drawRandomPixels();
      }
    };

    const drawRandomPixels = () => {
      const imageData = ctx.createImageData(canvas.width, canvas.height);
      const data = imageData.data;

      for (let i = 0; i < data.length; i += 4) {
        const color = colors[Math.floor(Math.random() * colors.length)];
        data[i] = color[0]; // Red
        data[i + 1] = color[1]; // Green
        data[i + 2] = color[2]; // Blue
        data[i + 3] = 255; // Alpha (fully opaque)
      }

      ctx.putImageData(imageData, 0, 0);
    };

    resizeCanvas();
    window.addEventListener("resize", resizeCanvas);

    return () => {
      window.removeEventListener("resize", resizeCanvas);
    };
  }, []);

  return (
    <Box
      as="canvas"
      ref={canvasRef}
      position="fixed"
      top="0"
      left="0"
      width="100%"
      height="100%"
      zIndex="-1"
    />
  );
};

export default RandomPixelBackground;
