/**
 * A utility class for performing various number-related operations.
 */
export class Numbers {
  /**
   * Generates a random double (floating-point) number between the specified min and max values.
   *
   * @param min - The minimum value (inclusive)
   * @param max - The maximum value (inclusive)
   * @returns A random floating-point number between min and max
   */
  static generateRandomDouble(min: number, max: number): number {
    if (min > max) {
      throw new Error("The min value cannot be greater than the max value.");
    }
    return Math.random() * (max - min) + min;
  }
}
