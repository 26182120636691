import React, { useMemo } from "react";
import * as THREE from "three";

interface EPlaneProps {
  size: [number, number, number];
  position: [number, number, number];
  color: string;
  radius?: number;
}

const EPlane: React.FC<EPlaneProps> = ({
  size,
  position,
  color,
  radius = 0,
}) => {
  const shape = useMemo(() => {
    const [width, height] = [size[0], size[1]];
    const shape = new THREE.Shape();
    const r = Math.min(radius, width / 2, height / 2);

    // Create rounded rectangle shape
    shape.moveTo(-width / 2 + r, -height / 2);
    shape.lineTo(width / 2 - r, -height / 2);
    shape.quadraticCurveTo(width / 2, -height / 2, width / 2, -height / 2 + r);
    shape.lineTo(width / 2, height / 2 - r);
    shape.quadraticCurveTo(width / 2, height / 2, width / 2 - r, height / 2);
    shape.lineTo(-width / 2 + r, height / 2);
    shape.quadraticCurveTo(-width / 2, height / 2, -width / 2, height / 2 - r);
    shape.lineTo(-width / 2, -height / 2 + r);
    shape.quadraticCurveTo(
      -width / 2,
      -height / 2,
      -width / 2 + r,
      -height / 2
    );

    return shape;
  }, [size, radius]);

  const geometry = useMemo(() => {
    const extrudeSettings = { depth: size[2], bevelEnabled: false };
    return new THREE.ExtrudeGeometry(shape, extrudeSettings);
  }, [shape, size]);

  return (
    <mesh geometry={geometry} position={position as unknown as THREE.Vector3}>
      <meshStandardMaterial
        attach="material"
        color={color}
        side={THREE.DoubleSide}
      />
    </mesh>
  );
};

export default EPlane;
