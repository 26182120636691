import { Box } from "@chakra-ui/react";
import { useEffect, useRef } from "react";
import * as THREE from "three";
import { Strings } from "../../Utils/Strings";

const GenBg = () => {
  const mountRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!mountRef.current) return;

    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight,
      0.1,
      1000
    );
    camera.position.z = 5;

    const renderer = new THREE.WebGLRenderer();
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setClearColor(0xffffff);
    const mountNode = mountRef.current; // Store a reference to the mount node
    if (mountNode) {
      mountNode.appendChild(renderer.domElement);
    }

    const createCurve = (offsetX: number, color: string) => {
      const points = [];
      const curveLength = 1000;
      for (let i = 0; i < curveLength; i++) {
        const x = (i - curveLength / 2) * 0.1 + offsetX;
        const y = Math.sin(i * 0.2) * 2;
        points.push(new THREE.Vector3(x, y, 0));
      }

      const geometry = new THREE.BufferGeometry().setFromPoints(points);
      const material = new THREE.LineBasicMaterial({ color });
      const line = new THREE.Line(geometry, material);
      return line;
    };

    const lines: THREE.Line[] = [];
    const lineCount = 22;
    for (let i = 0; i < lineCount; i++) {
      const offsetX = (Math.random() - 0.5) * 10;
      const color = Strings.generateRandomColor(); // Generate random color
      const line = createCurve(offsetX, color);
      line.position.y = (Math.random() - 0.5) * 5;
      scene.add(line);
      lines.push(line);
    }

    // Animation loop
    const animate = () => {
      requestAnimationFrame(animate);

      lines.forEach((line, index) => {
        // Make the lines swim
        line.position.y += Math.sin(Date.now() * 0.001 + index) * 0.01;
        if (line.position.y > 5) line.position.y = -5;
      });

      renderer.render(scene, camera);
    };

    animate();

    const handleResize = () => {
      renderer.setSize(window.innerWidth, window.innerHeight);
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      if (mountNode) {
        mountNode.removeChild(renderer.domElement);
      }
    };
  }, []);

  return <Box ref={mountRef} w="100vw" h="100vh" />;
};

const EMenuPageBg = () => {
  return (
    <Box bg="white" w="100vw" h="100vh" position="relative">
      <GenBg />
    </Box>
  );
};

export default EMenuPageBg;
