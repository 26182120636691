// Supports weights 300-700
import "@fontsource-variable/red-hat-text";

import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import MainNavigator from "./Navigation/MainNavigator";

import theme from "./theme";
import Fonts from "./Utils/Fonts";

const App = () => {
  return (
    <ChakraProvider theme={theme}>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <Fonts />
      <MainNavigator />
    </ChakraProvider>
  );
};

export default App;
