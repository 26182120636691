import { Box, Divider, Heading, Spacer, Text } from "@chakra-ui/react";
import RandomPixelBackground from "../Views/RandomPixelBackground";
import { Strings } from "../Utils/Strings";

const KenatzumuPrismPage = () => {
  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      flexDirection={"column"}
      h={"100vh"}
      paddingX="2rem"
    >
      <RandomPixelBackground />
      <Heading color="branding.coral" fontWeight={300}>
        {Strings.KENATZUMU_PRISM_STRINGS.PROJECT_NAME}
      </Heading>
      <Divider borderColor="branding.coral" />
      <Box display="flex" flexDirection="column">
        <Text color="branding.coral" fontWeight={300} fontSize="22">
          {Strings.KENATZUMU_PRISM_STRINGS.SHORT_PROJECT_NAME}
        </Text>
        <Text color="branding.safeWhite" fontWeight={300}>
          {Strings.KENATZUMU_PRISM_STRINGS.ABOUT}
        </Text>
      </Box>
      <Spacer />
    </Box>
  );
};

export default KenatzumuPrismPage;
