import React from "react";
import { Box } from "@chakra-ui/react";
import { Canvas } from "@react-three/fiber";
import { GeneralDimensions } from "../Utils/Dimensions";
import CMYCubeRender from "./CMYCubeRender";
import useCMYCubeScene from "./useCMYCubeScene";

const CMYCubeScene: React.FC = () => {
  const { meshRef, handleMouseDown, handleMouseUp, handleMouseMove } =
    useCMYCubeScene();

  return (
    <Box
      style={{
        height: GeneralDimensions.HalfFullHeight,
        width: GeneralDimensions.FullWidth,
      }}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
    >
      <Canvas camera={{ position: [0, 0, 100], near: 1, far: 200 }}>
        <group ref={meshRef}>
          <ambientLight intensity={10} />
          <CMYCubeRender />
        </group>
      </Canvas>
    </Box>
  );
};

export default CMYCubeScene;
