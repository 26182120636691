import { Box, Heading, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { Strings } from "../../Utils/Strings";

const ProjectsBox = () => {
  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      alignSelf={"center"}
      flexDirection={"column"}
    >
      <Heading>{Strings.GENERAL_STRINGS.PROJECTS_STRING}</Heading>
      <Link to="/kenatzumu-prism">
        <Text>{Strings.KENATZUMU_PRISM_STRINGS.SHORT_PROJECT_NAME}</Text>
      </Link>
      {/* <Link to="/daily-sheets">
        <Text>{Strings.DAILY_SHEETS_STRINGS.SHORT_PROJECT_NAME}</Text>
      </Link> */}
      <Link to="/experiments">
        <Text>Experiments</Text>
      </Link>
    </Box>
  );
};

export default ProjectsBox;
