/**
 * A utility class for performing various string-related operations.
 */
export class Strings {
  /**
   * Constants for general application strings.
   */
  static readonly GENERAL_STRINGS = Object.freeze({
    PROJECTS_STRING: "Projects",
    DESCRIPTION_STRING: "Description",
    KENATZUMU_STUDIO: "Kenatzumu Studio",
    COPYRIGHT: "© 2024 Crişan Horea. All Rights Reserved.",
  });

  /**
   * Constants for strings related to the Kenatzumu Prism Project.
   */
  static readonly KENATZUMU_PRISM_STRINGS = Object.freeze({
    PROJECT_NAME: "Kenatzumu Prism Project",
    SHORT_PROJECT_NAME: "Prism",
    ABOUT: `This project aims to develop a mobile application that can instantly capture and interpret 
    the world around us. Using advanced image recognition technology, the app will identify objects, 
    scenes, and environments in real-time, offering users a deeper understanding of their surroundings. 
    By bridging the gap between visual perception and digital analysis, this tool seeks to enhance how 
    we interact with and comprehend our immediate environment.`,
  });

  static readonly DAILY_SHEETS_STRINGS = Object.freeze({
    PROJECT_NAME: "Daily Sheets Project",
    SHORT_PROJECT_NAME: "Sheets",
  });

  /**
   * Generates a random RGB color string.
   *
   * @returns A string representing a random RGB color, e.g., 'rgb(123,45,67)'.
   */
  static generateRandomColor(): string {
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);
    return `rgb(${r},${g},${b})`;
  }

  /**
   * Generates a random RGB color based on an input hex color.
   *
   * @param hexColor - A string representing the base hex color, e.g., '#7B2D43'.
   * @returns A string representing a new random RGB color based on the input, e.g., 'rgb(150,60,80)'.
   */
  static generateRandomColorBasedOn(hexColor: string): string {
    // Validate and clean the hex input (e.g., remove '#')
    const cleanHex = hexColor.replace("#", "");

    if (cleanHex.length !== 6) {
      throw new Error("Invalid hex color format. Expected format: #RRGGBB");
    }

    // Convert hex to RGB
    const r = parseInt(cleanHex.substring(0, 2), 16);
    const g = parseInt(cleanHex.substring(2, 4), 16);
    const b = parseInt(cleanHex.substring(4, 6), 16);

    // Adjust the RGB values by adding a random variation
    const adjust = (value: number) => {
      const variation = Math.floor(Math.random() * 100) - 50; // Adjust by +/- 50
      return Math.min(255, Math.max(0, value + variation)); // Ensure the value stays within [0, 255]
    };

    const newR = adjust(r);
    const newG = adjust(g);
    const newB = adjust(b);

    return `rgb(${newR},${newG},${newB})`;
  }

  /**
   * Generates a UUID v4 string.
   *
   * @returns A string representing a UUID v4, e.g., 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.
   */
  static generateUUID(): string {
    // Implementation for generating UUID v4
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0;
        const v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16).toUpperCase(); // Convert characters to uppercase
      }
    );
  }
}
