enum WebDimensions {
  TitleFontSize = "12vw",
  SubtitleFontSize = 22,
}

enum TabletDimensions {
  TitleFontSize = "8vw",
  SubtitleFontSize = 18,
}

enum MobileDimensions {
  TitleFontSize = "4vw",
  SubtitleFontSize = 14,
}

enum GeneralDimensions {
  FullWidth = "100vw",
  FullHeight = "100vh",
  HalfFullWidth = "50vw",
  HalfFullHeight = "50vh",
}

export { WebDimensions, MobileDimensions, TabletDimensions, GeneralDimensions };
