enum LightColors {
  Background = "#30292C",
  Gray = "#838181",
  SafeWhite = "#FCFCFC",
  SafeBlack = "#252525",
}

enum DarkColors {
  Background = "#F0F0F0",
  Gray = "#838181",
  SafeWhite = "#FCFCFC",
  SafeBlack = "#252525",
}

enum PersistentColors {
  Cyan = "#00FFFF",
  Magenta = "#FF00FF",
  Yellow = "#FFFF00",
  White = "#FFFFFF",
  Black = "#000000",
  Coral = "#FF7F50",
  RadarBlipGreen = "#96f486",
}

export { PersistentColors, DarkColors, LightColors };
