import EPlane from "../../Views/ExperimentsViews/E1Views/EPlane";
import ERotatingCanvas from "../../Views/ExperimentsViews/ERotatingCanvas";
import ESphereGrid from "../../Views/ExperimentsViews/E1Views/ESphereGrid";
import { Strings } from "../../Utils/Strings";

const E1Page = () => {
  const backPlanRadius: number = 0;
  const frontPlanRadius: number = 0.05;

  const backPlaneColor: string = Strings.generateRandomColorBasedOn("#CBCE91");
  const frontPlaneColor: string = Strings.generateRandomColorBasedOn("#FCFBF7");

  return (
    <ERotatingCanvas>
      <EPlane
        size={[1, 1, 0.01]}
        position={[0, 0, 0]}
        color={backPlaneColor}
        radius={backPlanRadius}
      />
      <EPlane
        size={[0.88, 0.88, 0.01]}
        position={[0, 0, 0.02]}
        color={frontPlaneColor}
        radius={frontPlanRadius}
      />
      <ESphereGrid />
    </ERotatingCanvas>
  );
};

export default E1Page;
