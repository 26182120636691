import { useCallback, useEffect, useRef, useState } from "react";
import { Group } from "three";

const useCMYCubeScene = () => {
  const meshRef = useRef<Group>(null);

  const [isDragging, setIsDragging] = useState(false);
  const [prevMousePosition, setPrevMousePosition] = useState<
    [number, number] | null
  >(null);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isDragging && meshRef.current) {
        meshRef.current.rotation.x += 0.01;
        meshRef.current.rotation.y += 0.01;
      }
    }, 30);

    return () => clearInterval(interval);
  }, [isDragging]);

  const handleMouseDown = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      setIsDragging(true);
      setPrevMousePosition([event.clientX, event.clientY]);
    },
    []
  );

  const handleMouseUp = useCallback(() => {
    setIsDragging(false);
    setPrevMousePosition(null);
  }, []);

  const handleMouseMove = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (isDragging && prevMousePosition) {
        const [prevX, prevY] = prevMousePosition;
        const deltaX = event.clientX - prevX;
        const deltaY = event.clientY - prevY;

        if (meshRef.current) {
          meshRef.current.rotation.x += deltaY * 0.01;
          meshRef.current.rotation.y += deltaX * 0.01;
        }

        setPrevMousePosition([event.clientX, event.clientY]);
      }
    },
    [isDragging, prevMousePosition]
  );

  return {
    meshRef,
    handleMouseDown,
    handleMouseUp,
    handleMouseMove,
  };
};

export default useCMYCubeScene;
