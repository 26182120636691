import { Box, Divider, Heading, Spacer } from "@chakra-ui/react";
import RandomPixelBackground from "../Views/RandomPixelBackground";
import { Strings } from "../Utils/Strings";

const DailySheetsPage = () => {
  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      flexDirection={"column"}
      h={"100vh"}
      paddingX="2rem"
    >
      <RandomPixelBackground />
      <Heading color="branding.coral" fontWeight={300}>
        {Strings.DAILY_SHEETS_STRINGS.PROJECT_NAME}
      </Heading>
      <Divider borderColor="branding.coral" />
      <Spacer />
    </Box>
  );
};

export default DailySheetsPage;
