import { Button, Box, VStack } from "@chakra-ui/react";
import { IoDownloadOutline, IoReload } from "react-icons/io5";
import { Export3DObjectManager } from "../../Managers/Export3DObjectManager";
import * as THREE from "three";

interface FloatingButtonsProps {
  scene: THREE.Scene;
}

const FloatingButtons: React.FC<FloatingButtonsProps> = ({ scene }) => {
  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <Box position="fixed" bottom="20px" right="20px" zIndex="1000">
      <Box display="flex" flexDirection="column" gap="10px">
        <Button
          onClick={() => Export3DObjectManager.exportToObj(scene)}
          width="60px"
          height="60px"
          borderRadius="full"
          bg="blue.500"
          color="white"
          _hover={{ bg: "blue.600" }}
        >
          <VStack spacing="1px">
            <Box as="span">.OBJ</Box>
            <IoDownloadOutline size="20px" />
          </VStack>
        </Button>
        <Button
          onClick={() => Export3DObjectManager.exportToGLTF(scene, false)}
          width="60px"
          height="60px"
          borderRadius="full"
          bg="green.500"
          color="white"
          _hover={{ bg: "green.600" }}
        >
          <VStack spacing="1px">
            <Box as="span">.gLTF</Box>
            <IoDownloadOutline size="20px" />
          </VStack>
        </Button>
        <Button
          width="60px"
          height="60px"
          borderRadius="full"
          bg="pink.500"
          color="white"
          _hover={{ bg: "pink.600" }}
          onClick={handleRefresh}
        >
          <IoReload size="20px" />
        </Button>
      </Box>
    </Box>
  );
};

export default FloatingButtons;
