import React from "react";
import { Vector3 } from "three";
import { Numbers } from "../../../Utils/Numbers";
import { Strings } from "../../../Utils/Strings";
import { PlaneGeometry, RingGeometry, CircleGeometry } from "three";

interface SphereGridProps {
  gridSize?: number;
  spacing?: number;
}

const generateGridPositions = (size: number, spacing: number): Vector3[] => {
  const positions: Vector3[] = [];
  for (let x = -size; x <= size; x++) {
    for (let y = -size; y <= size; y++) {
      positions.push(new Vector3(x * spacing, y * spacing, 0));
    }
  }
  return positions;
};

const ESphereGrid: React.FC<SphereGridProps> = ({
  gridSize = 4.5,
  spacing = 0.085,
}) => {
  const positions = generateGridPositions(gridSize, spacing);

  return (
    <>
      {positions.map((position, index) => {
        const shouldDrawRing = Math.random() < 0.8; // 80% chance to draw the ring
        const ringInnerRadius = Numbers.generateRandomDouble(0.015, 0.02);
        const ringOuterRadius = Numbers.generateRandomDouble(0.02, 0.03);

        return (
          <group key={`Canvas${index}`}>
            <group key={`LineAndRing${index}`} position={position}>
              {/* Always draw the Line */}
              <mesh
                name={`Line${index}`}
                geometry={
                  new PlaneGeometry(
                    Numbers.generateRandomDouble(0.001, 0.001),
                    Numbers.generateRandomDouble(0.088, 0.088)
                  )
                }
                position={[0, 0, 0.06]} // Slight Z-offset
              >
                <meshStandardMaterial color={Strings.generateRandomColor()} />
              </mesh>

              {/* Conditionally draw the Ring and a Circle inside */}
              {shouldDrawRing && (
                <>
                  <mesh
                    name={`Ring${index}`}
                    geometry={
                      new RingGeometry(ringInnerRadius, ringOuterRadius, 100)
                    }
                    position={[0, 0, 0.06]} // Same position as the line
                  >
                    <meshStandardMaterial
                      color={Strings.generateRandomColor()}
                    />
                  </mesh>
                </>
              )}

              {/* Small Circle in the center of the Ring */}
              <mesh
                name={`CircleInsideRing${index}`}
                geometry={new CircleGeometry(ringInnerRadius * 0.6, 100)} // Make sure the circle is smaller than the inner radius
                position={[0, 0, 0.06]} // Slight Z-offset for stacking
              >
                <meshStandardMaterial color={Strings.generateRandomColor()} />
              </mesh>
            </group>
          </group>
        );
      })}
    </>
  );
};

export default ESphereGrid;
