import { Box, Heading, Spacer } from "@chakra-ui/react";
import CMYCubeScene from "../CMYCube/CMYCubeScene";
import { FooterBox } from "../Views/HomeViews/FooterBox";
import ProjectsBox from "../Views/HomeViews/ProjectsBox";
import { Strings } from "../Utils/Strings";
// import { Button, useColorMode } from "@chakra-ui/react";
import RandomPixelBackground from "../Views/RandomPixelBackground";

const HomePage = () => {
  // const { colorMode, toggleColorMode } = useColorMode();

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <RandomPixelBackground />
      <Box
        w="100%"
        h="10vh"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        paddingX="2rem"
      >
        <Heading fontWeight="300">
          {Strings.GENERAL_STRINGS.KENATZUMU_STUDIO}
        </Heading>
        {/* <Button onClick={toggleColorMode}>
          Toggle{" "}
          {colorMode === "light"
            ? "change to Dark (now on Light)"
            : "change to Light (now on Dark)"}
        </Button> */}
      </Box>
      <CMYCubeScene />
      <Spacer />
      <ProjectsBox />
      <Spacer />
      <FooterBox />
    </Box>
  );
};

export default HomePage;
