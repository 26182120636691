import * as THREE from "three";
import ERotatingCanvas from "../../Views/ExperimentsViews/ERotatingCanvas";

const Contour = ({
  geometry,
  color,
}: {
  geometry: THREE.BufferGeometry;
  color: string;
}) => {
  return (
    <lineSegments>
      <edgesGeometry args={[geometry]} />
      <lineBasicMaterial color={color} linewidth={2} />
    </lineSegments>
  );
};

const SimpleFace = () => {
  return (
    <>
      {/* Face */}
      <mesh position={[0, 0, 0]}>
        <sphereGeometry args={[0.25, 32, 32]} />
        <meshStandardMaterial color="yellow" />
        <Contour
          geometry={new THREE.SphereGeometry(0.25, 32, 32)}
          color="black"
        />
      </mesh>

      {/* Left Eye */}
      <mesh position={[-0.1, 0.1, 0.26]}>
        <sphereGeometry args={[0.05, 32, 32]} />
        <meshStandardMaterial color="white" />
        <Contour
          geometry={new THREE.SphereGeometry(0.05, 32, 32)}
          color="black"
        />
      </mesh>

      {/* Right Eye */}
      <mesh position={[0.1, 0.1, 0.26]}>
        <sphereGeometry args={[0.05, 32, 32]} />
        <meshStandardMaterial color="white" />
        <Contour
          geometry={new THREE.SphereGeometry(0.05, 32, 32)}
          color="black"
        />
      </mesh>

      {/* Mouth */}
      <mesh position={[0, -0.1, 0.26]}>
        <cylinderGeometry args={[0.1, 0.1, 0.03, 32]} />
        <meshStandardMaterial color="red" />
        <Contour
          geometry={new THREE.CylinderGeometry(0.1, 0.1, 0.03, 32)}
          color="black"
        />
      </mesh>
    </>
  );
};

const E2Page = () => {
  return (
    <ERotatingCanvas>
      <SimpleFace />
    </ERotatingCanvas>
  );
};

export default E2Page;
